import React from 'react'
import {graphql} from 'gatsby'
import Breadcrumbs from "../components/common/breadcrumbs";
import CurriculoForm from "../components/curriculo-form";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import LayoutSecondary from "../components/common/layout-secondary";
import ReactMarkdown from "react-markdown";
import SEO from "../components/common/seo";
import rehypeRaw from "rehype-raw";

const JobOfferTemplate = (props) => {

    const breadcrumbs = [
        {
            text: 'Talento',
            slug: '../../',
        },
        {
            text: 'Únete a Tecalis',
            slug: '../',
        },
        {
            text: props.data.strapiJobOffer.title,
            active: true,
        },
    ];

    return (
        <LayoutSecondary pageContext={props.pageContext} cookies={props.data.cookies}>
            <SEO title={props.data.strapiJobOffer.title + " | Oferta de empleo - Tecalis"}
                 description={"Buscamos " + props.data.strapiJobOffer.title + ". Déja tu CV y únete a un equipo de trabajo apasionado por la tecnología y el trabajo en equipo."}/>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            <section className="bg-grey pt-8 pt-md-11">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md">

                            {/** Heading */}
                            <h1 className="isplay-4 font-weight-bold spacing title-feed-2">
                                {props.data.strapiJobOffer.title}
                            </h1>

                            {/** LUGAR / TIPO DE HORARIO */}
                            <p className="font-size-lg text-blue-grid mb-5 spacing mb-md-0">
                                <i className="fe fe-map-pin"/> {props.data.strapiJobOffer.office.name}, {props.data.strapiJobOffer.office.country} &mdash;&nbsp;
                                <i className="fe fe-clock"/> {props.data.strapiJobOffer.workday}
                            </p>

                        </div>
                        <div className="col-auto">

                            <AnchorLink className="btn btn-primary spacing" to="#inscribirse"
                                        data-toggle="smooth-scroll"
                                        data-offset="0">
                                {props.data.jobOffer.frontmatter.apply}
                            </AnchorLink>

                        </div>
                    </div>
                    {/** / .row */}
                    <div className="row">
                        <div className="col-12">

                            {/** Divider */}
                            <hr className="my-6 my-md-8 border-gray-300"/>

                        </div>
                    </div>
                    {/** / .row */}
                    <div className="row">
                        <div className="col-12 col-md-8 check-list">

                            {/** Descripción oferta */}
                            <h3 className="font-weight-bold spacing">
                                Descripción de la oferta
                            </h3>
                            {/** List */}

                            <ReactMarkdown className="text-black-800 mb-6 mb-md-8 spacing"
                                           rehypePlugins={[rehypeRaw]}
                                           children={props.data.strapiJobOffer.offer}/>

                            {/** Características del trabajo */}
                            <h3 className="font-weight-bold spacing">
                                ¿Qué buscamos?
                            </h3>

                            {/** Texto */}
                            <ReactMarkdown className="text-black-800 mb-6 mb-md-8 spacing"
                                           rehypePlugins={[rehypeRaw]}
                                           children={props.data.strapiJobOffer.look_for}/>

                            {/** Requisitos mínimos */}
                            <h3 className="font-weight-bold spacing">
                                Requisitos mínimos
                            </h3>

                            {/** List */}
                            <ReactMarkdown className="text-black-800 mb-6 mb-md-8 spacing"
                                           rehypePlugins={[rehypeRaw]}
                                           children={props.data.strapiJobOffer.requirements}/>

                        </div>

                        <div className="col-12 col-md-4" data-aos="fade-down" data-aos-delay="600">


                            {/** Card */}
                            <div className="card shadow-light-lg lift">
                                <div className="card-body">

                                    {/** Heading */}
                                    <h3 className="font-weight-bold spacing">
                                        <i className="fe fe-help-circle"/> {props.data.jobOffer.frontmatter.heading}
                                    </h3><br/>

                                    {/** Text */}
                                    <p className="font-size-sm text-black-800">
                                        {props.data.jobOffer.frontmatter.text}
                                    </p><br/>

                                    {/** Heading */}
                                    <a href='mailto:&#112;&#101;&#111;&#112;&#108;&#101;&#064;&#116;&#101;&#099;&#097;&#108;&#105;&#115;&#046;&#099;&#111;&#109;'
                                       className="font-weight-bold font-size-sm text-decoration-none color-tecalis">
                                        {props.data.jobOffer.frontmatter.contactText} <i
                                        className="fe fe-arrow-right ml-3"/>
                                    </a>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>

            <section id="inscribirse" className="bg-grey pt-8 pt-md-11 pb-8 pb-md-14">
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            {/** Card */}
                            <div className="card card-border border-success shadow-light-lg">
                                <div className="card-body">

                                    <CurriculoForm data={props.data.formCurriculo}/>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>
        </LayoutSecondary>
    )
}

export default JobOfferTemplate

export const jobOfferPageQuery = graphql`
  query JobOfferTemplate($id: String!, $lang: String) {
    strapiJobOffer(
        id: {
            eq: $id
        }
    ) {
        title
        workday
        look_for
        requirements
        offer
        office{
            name
            country
        }
    }
    jobOffer: markdownRemark(
        frontmatter: { 
            lang: {
                eq: $lang
            }
            name: {
                eq: "job-offer/job-offer-template"
            }
        }) {
        frontmatter {
            apply
            heading
            text
            contactText
            contactLink
        }
    }
    formCurriculo: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "people/join-tecalis/curriculo-form"
                }
            }) {
            frontmatter {
                heading
                nameField
                namePlaceholder
                email
                emailPlaceholder
                phone
                phonePlaceholder
                file
                fileButton
                search
                privacyStart
                privacyLink
                privacyText
                conditionsLink
                conditionsText
                send
            }
        }
    site {
        siteMetadata {
            title
            twitterHandle
        }
    }
    cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
  }
`
